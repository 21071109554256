@font-face {
  font-family: ttMoons;
  src: url("./assets/fonts/ttmoons/TT\ Moons\ Trial\ Thin.otf");
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "ttMoons", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
