.App {
  height: 100vh;
}

a,
button {
  background: none;
  font-family: inherit;
  text-align: left;
  outline: none;
  border: none;
  text-decoration: none;
  color: inherit;
}

.container {
  padding: 20px 40px;
  max-width: 1440px;
  margin: 0 auto;
}

.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 0;
}

.hero__image {
  padding: 40px 0;
  width: 45%;
  display: none;
}

.hero__image picture img {
  width: 80%;
  border-radius: 5%;
  object-fit: contain;
}

.hero__content {
  flex: 1;
}

.hero h2 {
  font-size: 48px;
  line-height: 1.25;
  color: #4f2410;
  text-transform: uppercase;
}

.header {
  height: 80px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid black;
  justify-content: space-between;
}

.header .location-link {
  font-size: 24px;
  color: black;
  font-weight: 700;
}

.product,
.review,
.blog {
  padding: 40px 0;
}

.product img,
.product picture {
  margin-left: -40px;
  width: calc(100vw + 40px);
}

h3 {
  font-size: 36px;
  text-transform: uppercase;
  display: block;
  width: 100%;
}

.product__content,
.blog__content {
  padding: 40px 0;
  display: flex;
  flex-wrap: wrap;
}

.product__item {
  padding: 20px;
  flex: 1;
  min-width: 100%;
}

.product__item__image {
  min-height: 300px;
  width: 100%;
  background: lightblue;
  height: 300px;
  border-radius: 4%;
  overflow: hidden;
}

.product__item__image img,
.product__item__image picture {
  height: 100%;
  width: 100%;
  object-fit: cover;
  margin-left: 0;
  object-position: center;
}

.product__item h4 {
  font-size: 30px;
  margin: 8px 0;
}

.product__item h5 {
  font-size: 20px;
  line-height: 1;
}

.product__store-link,
.blog__content__viewAll {
  margin-top: 60px;
  width: 100%;
  text-align: center;
  font-size: 32px;
  text-decoration: none;
  color: #4f2410;
}

.blog__content__main {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.review {
  padding-top: 0;
}

.review__content__main {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 40px 0;
}

.review-item {
  padding: 4px 8px;
  font-size: 18px;
  margin: 4px;
  display: inline-flex;
  background: #e9dccd;
  color: #4f2410;
  border-radius: 12px;
  font-weight: 500;
}

.footer {
  display: flex;
  justify-content: flex-start;
  padding: 10px 0;
  border-top: 1px solid black;
  font-size: 16px;
  align-items: flex-end;
}

.footer p {
  margin-bottom: 12px;
}

.footer img {
  width: 60px;
  margin-right: 20px;
}

/* Start of Product page */
.product-page {
  min-height: calc(100vh - 200px);
}

.product-page__top {
  padding: 30px 0;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.product-page__image {
  flex: 1;
  width: 100%;
  max-width: 500px;
  margin-bottom: 20px;
}

.product-page__content {
  flex: 1;
  width: 100%;
  max-width: 600px;
}

.product-page__content h1 {
  margin-bottom: 24px;
  font-size: 24px;
}

.product-page__buy-button {
  display: block;
  border-radius: 5px;
  padding: 6px 24px;
  font-size: 20px;
  background: #4f2410;
  color: #e9dccd;
  margin-bottom: 24px;
  max-width: 240px;
  text-align: center;
}

.product-page__content p {
  line-height: 24px;
  font-size: 18px;
}

/* End of Product page */

@media screen and (min-width: 768px) {
  .product__item {
    min-width: 50%;
  }
}

@media screen and (min-width: 960px) {
  .hero__image {
    display: block;
  }
  .hero h2 {
    font-size: 72px;
  }
  .hero {
    padding: 0;
  }
  .product,
  .review {
    padding: 80px 0;
  }

  .blog {
    padding-top: 0px;
  }

  .product__content,
  .blog__content {
    padding: 80px;
  }

  .blog__content {
    padding-top: 0px;
  }
  h3 {
    font-size: 48px;
  }
  .product__item h4 {
    font-size: 36px;
    margin: 12px 0;
  }

  .product__item h5 {
    font-size: 24px;
  }
  .review__content {
    padding: 80px;
    padding-top: 0;
  }
  .review-item {
    padding: 6px 12px;
    font-size: 20px;
    margin: 6px;
    display: inline-flex;
    background: #e9dccd;
    color: #4f2410;
    border-radius: 16px;
    font-weight: 500;
  }
  .footer {
    padding: 30px 80px;
    font-size: 24px;
  }

  .footer img {
    width: 80px;
    margin-right: 40px;
  }
}

@media screen and (min-width: 1180px) {
  .product__item {
    min-width: 33%;
  }

  .product-page__top {
    padding: 80px 0;
    justify-content: space-around;
    flex-direction: row;
  }

  .product-page__image {
    margin-bottom: 0;
  }

  .product-page__content h1 {
    margin-bottom: 36px;
    font-size: 36px;
  }

  .product-page__buy-button {
    font-size: 28px;
    margin-bottom: 36px;
  }

  .product-page__content p {
    line-height: 30px;
    font-size: 20px;
  }
}
